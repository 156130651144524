<template>
  <div class style="background:#d1e1f9; ">
    <el-row class="memory-cont" style>
      <!-- left_common -->
      <el-col :span="6">
        <contentLeft type="6"></contentLeft>
      </el-col>
      <el-col :span="18" style="background: #fff;">
        <div class="con-base">
          <b>
            <span>|</span>空间内存：
          </b>
          <a>{{vipsession.total_storage-vipsession.use_storage}}MB</a>
          <span>/{{vipsession.use_storage}}MB</span>
        </div>
        <div class="memory-cont-right">

          <div class="expansion">
            <span>
              扩充：
              <span>**币/G</span>
            </span>
            <p>
              <button @click="kuochong()">扩充</button>
            </p>
          </div>
          <div class="expansion-two">
            <span>开通会员享受1000G和其他专享权益</span>
            <p>
              <button @click="kuochong1()">扩充</button>
              <span>¥19900元/年</span>
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { requestStorage  } from "../api/request";
import contentLeft from '@/components/contentLeft.vue'
export default {
  name: "VipPrivilge",
  components: {
    contentLeft,
  },
    data () {
        return {
            vipsession:''
        }
    },
    methods:{
        kuochong(){
            this.$router.push("MySpace")
        },
        kuochong1(){
            this.$router.push("VipPrivilge")
        }
    },
    mounted(){
        if(this.Account.GetSession()==null){
            this.$router.push({ path: "/" });
            return
        }
        requestStorage().then(res => {
            localStorage.setItem("storage",JSON.stringify(res.ret));
            let storage_nav=this.Account.GetStorage();
            this.vipsession=storage_nav
        })
    }
};
</script>
<style scoped>

.memory-cont{
width:1350px;
background-color: #d1e1f9;
padding: 20px;
display: flex;
justify-content: flex-start;
margin: 0 auto;
}
.memory-cont-right{
background-color: #fff;
overflow: hidden;
padding: 10px;
}
.con-base{
  height: 80px;
  line-height: 80px;
  font-size: 16px;
  border-bottom: 1px solid #efeff0;;
}
.con-base b{
  margin-left: 50px;
  font-size: 16px;
}
.con-base b span,.con-detail b span{
  color: #007aff;
  margin-right: 7px;
}
.con-base a{
  color: #007aff;
  font-size: 26px;
}
.con-base b{
margin-left: 50px;
}
.expansion{
background-image: url(../assets/img/ordinary.png) ;
background-repeat:no-repeat;
height: 101px;
font-size: 14px;
color: #fff;
padding: 29px 0 0 20px;
border-radius: 5px;
margin-top: 16px;
margin-bottom: 22px;
}
/* .expansion span{
  margin-top: 40px;
} */
.expansion p,.expansion-two p{
  margin-top: 38px;
}
.expansion p{
  width: 101px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #1473cf;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 2px;

}
.expansion-two{
background-image: url(../assets/img/vipidentity.png);
background-repeat:no-repeat;
height: 101px;
color: #fff;
padding: 29px 0 0 20px;
border-radius: 5px;
}
.expansion p button{
    background: #3a78e5 !important;
}
.expansion-two p button,.expansion p button{
  width: 101px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #e5c06f;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 2px;
}
.expansion-two p span{
  font-size: 14px;
}
</style>
